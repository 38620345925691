// Stimulus controller for copying value from one input to other inputs matching a specified css selector
//
// Terminology:
//   frominput: The input that other inputs will be synced to.
//   tosel: The css selector for the inputs that will be synced to frominput.
// Setup:
//   1) Wrap frominput and all the tosel inputs in <div data-controller="syncinput">
//   2) Add the stimulus controller action/event to the frominput. (data-action="syncinput#update")
//      Add the css selector for the tosel inputs. (eg. data-syncinput-tosel=".max_signups_per_slot")
//      <%= f.number_field :max_signups_per_slot,
//          data: {action: "syncinput#update", 
//                 syncinput_tosel: ".max_signups_per_slot" } %> 
//   3) Make sure the elements that will be synced match the tosel css selector.  
//
import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
  }

  update() {
    this.element.querySelectorAll(this.target_selector).forEach(targ => { targ.value = this.frominput_val })
  }

  get frominput_val() {
    return event.target.value
  }

  get target_selector() {
    return event.target.getAttribute("data-syncinput-tosel")
  }
}
