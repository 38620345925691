import Sortable from 'sortablejs';
   
    // Check/uncheck all boxes on email volunteers page
    $(document).on("click", ".event_new_email_to_volunteers .check_all", function( e ) {
      e.preventDefault();
      $('.event_new_email_to_volunteers :checkbox').prop('checked', true) ;
    });

    $(document).on("click", ".event_new_email_to_volunteers .check_none", function( e ) {
      e.preventDefault();
      $('.event_new_email_to_volunteers :checkbox').prop('checked', false) ;
    });

    // Update image preview when a new image is chosen
    $(document).on("change", ".event_edit input#event_photo, .event_new input#event_photo", function( e ) {
      e.preventDefault();
      $('.event_edit .event-photo-preview img, .event_new .event-photo-preview img').attr('src', URL.createObjectURL(e.target.files[0]))
    });

    // Show and setup buttons to reorder how jobs are listed
    $(document).on("click", ".event_show #change_job_order", function( e ) {
      e.preventDefault();
      $('.buttons_for_sorting_jobs').show();
      $('.job_sort_handle').show();
      var sortable = Sortable.create(listOfJobs, {
        handle: '.glyphicon-move',
        animation: 150,
        scroll: true,
        scrollSensitivity: 50,
        onUpdate: function(e) {
          var job_order = sortable.toArray();
          document.getElementById('job_sort_order').value = JSON.stringify(job_order);
        }
      });
      $(document).on('sortJobs:after-sort', function(e) {
        var job_order = sortable.toArray();
        document.getElementById('job_sort_order').value = JSON.stringify(job_order);
      });

      $(this).blur(); //Chrome bug keeps button in focused state so force unfocus
      $(this).attr("disabled", true);
    });

    // up-down arrows to move jobs
    // https://codepen.io/EightArmsHQ/post/moving-things-up-and-down-with-jquery
    $(document).on("click", ".event_show .glyphicon-arrow-up", function( e ) {
      e.preventDefault();
      var $current = $(this).closest('div.job-row')
      var $previous = $current.prev('div.job-row');
      if($previous.length !== 0){
        $current.insertBefore($previous);
      }
      $(this).trigger('sortJobs:after-sort', []);
      return false;
    });
    $(document).on("click", ".event_show .glyphicon-arrow-down", function( e ) {
      e.preventDefault();
      var $current = $(this).closest('div.job-row')
      var $next = $current.next('div.job-row');
      if($next.length !== 0){
        $current.insertAfter($next);
      }
      $(this).trigger('sortJobs:after-sort', []);
      return false;
    });

    // Change grouping of events based on View selector on events_index page
    $(document).on("change", "div.event_index #event_grouping", function( event ) {
      event.preventDefault();
      var selected = $(event.target).val() || "";
      var url_base = window.location.protocol + "//" + window.location.host + window.location.pathname;
      var url_params = new URLSearchParams(location.search);
      if(selected == "Jobs Grouped by Day") {
        url_params.set("group_by_date", "true");
        window.location.assign(url_base+'?'+url_params.toString());
      } else if(selected == "Sortable List of Events"){
        url_params.delete("group_by_date");
        window.location.assign(url_base+'?'+url_params.toString());
      }
      // Reset view selector
      $("div.event_index #event_grouping option[value='View']").prop('selected', true);
      //window.location.href='/schools/10/events?group_by_date=true&tab=*Summer*Camp*'
    });

    // Hide/show events based on Event Tab Filter selector
    $(document).on("change", "div.event_index #event_tab_filter, div.event_index #event_filter", function( e ) {
      e.preventDefault();
      var event_filter = $('select#event_filter:visible option:selected').val() || ""
      var event_tab_filter = $('select#event_tab_filter:visible option:selected').val() || ""

      if((event_tab_filter == "") && (event_filter == "")) {
        $("div[data-event-filter-id], div[data-event-tab-id]").show();
      } else if(event_filter == "unspecified") {
        if(event_tab_filter == ""){
          var filter_selector = '[data-event-filter-id=""]';
          $("div[data-event-filter-id], div[data-event-tab-id]").show();
          $("div[data-event-tab-id]").not(filter_selector).hide();  
        } else {
          var filter_selector = '[data-event-tab-id="'+event_tab_filter+'"]'+'[data-event-filter-id=""]';
          $("div[data-event-filter-id], div[data-event-tab-id]").show();
          $("div[data-event-tab-id]").not(filter_selector).hide();  
        }
      } else if(event_tab_filter == "") {
        var filter_selector = '[data-event-filter-id="'+event_filter+'"]';
        $("div[data-event-filter-id], div[data-event-tab-id]").show();
        $("div[data-event-filter-id]").not(filter_selector).hide();  
      } else if(event_filter == "") {
        var filter_selector = '[data-event-tab-id="'+event_tab_filter+'"]';
        $("div[data-event-filter-id], div[data-event-tab-id]").show();
        $("div[data-event-tab-id]").not(filter_selector).hide();  
      } else {
        var filter_selector = '[data-event-tab-id="'+event_tab_filter+'"]'+'[data-event-filter-id="'+event_filter+'"]';
        $("div[data-event-filter-id], div[data-event-tab-id]").show();
        $("div[data-event-tab-id], div[data-event-filter-id]").not(filter_selector).hide();  
      }
    });

    // Reset the filters whenever event type tabs are changed
    $(document).on('shown.bs.tab', function(e) {
      $("div[data-event-filter-id], div[data-event-tab-id]").show();
      $("div.event_index #event_filter option[value='']").prop('selected', true);
      $("div.event_index #event_tab_filter option[value='']").prop('selected', true);
    });



    // Customize radio button labels in 'Generate jobs by shift' when starting date changes.
    function update_gen_daily_interval(starts_obj){  
      var starts = $(starts_obj).val();
      var url = '/schools/0/volunteer_jobs/gen_daily_interval_label';
      $('#global_modal').find("[data-gen-daily-interval='every_week']").parent('label').find('span').load(url,{interval: 'every_week',starts: starts});      
      $('#global_modal').find("[data-gen-daily-interval='every_month']").parent('label').find('span').load(url,{interval: 'every_month',starts: starts});      
    }

    $(document).on("shown.bs.modal", function( e ) {
      var starts_obj = $('#global_modal .field_gen_daily_first_day');
      update_gen_daily_interval(starts_obj);
      starts_obj.on('dp.change', function(e) {
        update_gen_daily_interval(this);
      });
    });
  
    // Calculate number of slots and volunteers 'Generate jobs by shift' when shift length, gap, etc. change and dynamically show at bottom of page.
    function update_calculated_shift_count(){  
      var gen_shift_day         = $('#global_modal .field_gen_shift_day        ').val();
      var gen_shift_time_starts = $('#global_modal .field_gen_shift_time_starts').val();
      var gen_shift_time_ends   = $('#global_modal .field_gen_shift_time_ends  ').val();
      var gen_shift_length      = $('#global_modal .field_gen_shift_length     ').val();
      var gen_shift_gap         = $('#global_modal .field_gen_shift_gap        ').val();
      var num_vols_needed       = $('#global_modal .field_num_vols_needed      ').val();
      var url = '/schools/0/volunteer_jobs/gen_calculate_shift_count';
      $('#global_modal').find("#calculated_shift_count").hide().load(url,
        {gen_shift_day: gen_shift_day, gen_shift_time_starts: gen_shift_time_starts, gen_shift_time_ends: gen_shift_time_ends,
         gen_shift_length: gen_shift_length, gen_shift_gap: gen_shift_gap, num_vols_needed: num_vols_needed}).fadeIn('fast');      
    }

    $(document).on("shown.bs.modal", function( e ) {
      var obj_gen_shift_day         = $("#global_modal .field_gen_shift_day        ");
      var obj_gen_shift_time_starts = $("#global_modal .field_gen_shift_time_starts");
      var obj_gen_shift_time_ends   = $("#global_modal .field_gen_shift_time_ends  ");
      var obj_gen_shift_length      = $("#global_modal .field_gen_shift_length     ");
      var obj_gen_shift_gap         = $("#global_modal .field_gen_shift_gap        ");
      var obj_num_vols_needed       = $("#global_modal .field_num_vols_needed      ");
      update_calculated_shift_count();
      obj_gen_shift_day.on('dp.change', function(e) {
        update_calculated_shift_count();
      });
      obj_gen_shift_time_starts.on('dp.change', function(e) {
        update_calculated_shift_count();
      });
      obj_gen_shift_time_ends.on('dp.change', function(e) {
        update_calculated_shift_count();
      });
      obj_gen_shift_length.on('keyup input', function(e) {
        update_calculated_shift_count();
      });
      obj_gen_shift_gap.on('keyup input', function(e) {
        update_calculated_shift_count();
      });
      obj_num_vols_needed.on('keyup input', function(e) {
        update_calculated_shift_count();
      });
    });

    // Update Rotations (Special ids) when Academic Year is Chenaged
    function update_gen_rotation_specials_calendar(district_calendar_obj){  
      var district_calendar_id = $(district_calendar_obj).val();
      var url = '/schools/0/volunteer_jobs/gen_rotation_specials_calendar_select';
      $('#global_modal').find("select#gen_rotation_specials_calendar_id").load(url,{district_calendar_id: district_calendar_id});      
    }

    $(document).on("shown.bs.modal", function( e ) {
      var district_calendar_obj = $('#global_modal .field_gen_rotation_district_calendar_id');
      //update_gen_rotation_specials_calendar(district_calendar_obj);
      district_calendar_obj.on('change', function(e) {
        update_gen_rotation_specials_calendar(this);
      });
    });

