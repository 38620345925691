// Stimulus controller for adding notes to conference slots by clicking or dragging.
//
//
import { Controller } from "stimulus";
import DragSelect from "dragselect";

export default class extends Controller {
  static values = { cdays: Array }

  ds_objs = []; // DragSelect instances; one for each conference day

  connect() {
    this.cdaysValue.forEach((cday, index) => {
      this.ds_objs[index] = new DragSelect({
        selectables: document.querySelectorAll(this.cell_selector()),
        area: document.querySelector(this.area_selector(cday)),
        draggability: false,
        dragKeys: {up:[], down:[], left:[], right:[]},
        immediateDrag: false
      }); 
      this.ds_objs[index].subscribe('callback', ({ items, event }) => {
        this.edit_notes(items);
        this.ds_objs[index].clearSelection();
        this.clearSelection()
      }); 
    });
  }

  disconnect() {
    this.cdaysValue.forEach((cday, index) => {
      this.ds_objs[index].stop();
    });
  }

  area_selector(cday) {
    // convert conference_day_id to css class that can be used to select that day's table of appointments
    return(`.conference_notes .cdt_${cday}`);
  }

  cell_selector() {
    return(".conference_notes .sl");
  }

  clearSelection() {
    // deselect any text that got selected in the select box by the browser
    // https://stackoverflow.com/questions/6562727/is-there-a-function-to-deselect-all-text-using-javascript
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.selection) {
      document.selection.empty();
    }
  }

  edit_notes(slot_nodes) {
    if(slot_nodes.length > 0) {
      var school = slot_nodes[0].parentNode.dataset["school"];
      var conference = slot_nodes[0].parentNode.dataset["conference"];
      var conference_day = slot_nodes[0].parentNode.dataset["conferenceDay"];
      var slots = []
      slot_nodes.forEach((slot_node, index) => {
        slots[index] = slot_node.dataset["slot"];
      });
      var path = "/schools/"+school+"/conferences/"+conference+"/conference_days/"+conference_day+"/conference_slots/new_notes";
      $.get(path, {slots: JSON.stringify(slots)} );
    }
  }



}
