// Stimulus controller for hiding/showing html when a button or link is clicked
//
// Terminology:
//   actor: The link, button, etc. that, when clicked, hides or shows content
//   content: The html (wrapped in div or span) that is hidden or shown
// Setup:
//   1) Wrap actor and content in <div data-controller="hideshow" data-hideshow-state="hide">
//   2) Add data attributes to actor.  Here's an example:
//      <%= link_to "Click here to show advanced settings" , 'javascript:void(0)', 
//          data: { action: "hideshow#toggleContent", 
//                  hideshow_hidden_html: "Click here to show advanced settings",
//                  hideshow_shown_html:  "Click here to hide advanced settings"} %>
//
//      Here's an example for a password field:
//      <div class="input-group">
//        <%= f.password_field :password, id: 'password', 
//            class: 'form-control no_form_focus', 
//            data: {hideshow_target:"password"} %>
//        <span class="input-group-btn">
//          <input type="button" 
//                 class="btn btn-default form-control"
//                 value= "<%= t('show') %>"
//                 data-action="click->hideshow#togglePassword" 
//                 data-hideshow-hidden-html="<%= t('show') %>"
//                 data-hideshow-shown-html= "<%= t('hide') %>" >
//        </span>
//      </div>
//   3) Wrap content in div with data-target="hideshow.content".  Example:
//        <div class="row job_settings" data-target="hideshow.content">
//
// Note that the hideshow_hidden_html and hideshow_shown_html attributes are optional and can be safely eliminated from actor
// if you dont't want actor text to change when content is hidden or shown.

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content", "password" ]

  connect() {
  	if(this.data.has("state") && (this.hasContentTarget || this.hasPasswordTarget)) {
      this.hideOrShowContent()
      this.hideOrShowPassword()
  	} else {
  		console.log("Warning: hideshow_controller requires data-hideshow-state on controller" 
  			+ " and data-target='hideshow.content' or data-target='hideshow.password' on content")
    }
  }

  toggleContent(e) {
  	this.toggleControllerHideShowState()
  	this.setActorHtml(e.target)
  	this.hideOrShowContent() 
  }

  togglePassword(e) {
  	this.toggleControllerHideShowState()
  	this.setActorHtml(e.target)
  	this.hideOrShowPassword() 
  }

  toggleControllerHideShowState(e) {
    if(this.data.has("state")) {
  	  if(this.data.get("state") == "hide") {
  		  this.data.set("state", "show")
  	  } else {
  		  this.data.set("state", "hide")
  	  }
  	}
  }

  setActorHtml(actor) {
    if(actor.hasAttribute("data-hideshow-hidden-html") && actor.hasAttribute("data-hideshow-shown-html")) {
  	  if(this.data.get("state") == "hide") {
  	  	if(actor instanceof HTMLAnchorElement) {
          actor.innerHTML=actor.getAttribute("data-hideshow-hidden-html")
        } else if(actor instanceof HTMLInputElement) {
          actor.value=actor.getAttribute("data-hideshow-hidden-html")
        }
  	  } else {
  	  	if(actor instanceof HTMLAnchorElement) {
          actor.innerHTML=actor.getAttribute("data-hideshow-shown-html")
        } else if(actor instanceof HTMLInputElement) {
          actor.value=actor.getAttribute("data-hideshow-shown-html")
        }
  		}
  	}
  }

  hideOrShowContent() {
    if(this.data.has("state") && this.hasContentTarget) {
  	  if(this.data.get("state") == "hide") {
  		  this.contentTarget.style.display = "none"
  	  } else {
  		  this.contentTarget.style.display = "block"
  		}
  	}
  }

  hideOrShowPassword() {
    if(this.data.has("state") && this.hasPasswordTarget) {
  	  if(this.data.get("state") == "hide") {
  		  this.passwordTarget.type = "password"
  	  } else {
  		  this.passwordTarget.type = "text"
  		}
  	}
  }


}

