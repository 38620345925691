import Trix from "trix"

addHeadingAttributes()
addFgColorAttributes()
addBgColorAttributes()
//addPreAttributes()

addEventListener("trix-initialize", function (event) {
    new RichText(event.target);
    if(browserIsMobile()) {
      putToolbarAfterEditor(event.target);
    }
})

addEventListener("trix-action-invoke", function (event) {
  if (event.actionName == "x-horizontal-rule") insertHorizontalRule()

  function insertHorizontalRule() {
    event.target.editor.insertAttachment(buildHorizontalRule())
  }

  function buildHorizontalRule() {
    return new Trix.Attachment({ content: "<hr>", contentType: "vnd.rubyonrails.horizontal-rule.html" })
  }
})

// Disable file uploads
addEventListener("trix-file-accept", event => {
  event.preventDefault()
})

class RichText {

  constructor(element) {
    this.element = element

    // Don't try to change editor button if they have already been changed.
    // This situation occurs if an existing toolbar is cloned by duplicate_div.
    if( this.buttonGroupBlockTools.contains(this.originalHeadingButton) ) {
      this.insertHeadingElements()
      this.insertDividerElements()
      this.insertColorElements()
      this.removeQuoteButton()
      this.removeAttachButton()
      this.renameCodeButton()
    }
  }

  insertHeadingElements() {
    this.removeOriginalHeadingButton()
    //this.insertPreButton()
    this.insertNewHeadingButton()
    this.insertHeadingDialog()
  }

  removeOriginalHeadingButton() {
    this.buttonGroupBlockTools.removeChild(this.originalHeadingButton)
  }


  renameCodeButton() {
    this.codeButton.title = "Monospaced"
  }

  removeQuoteButton() {
    this.buttonGroupBlockTools.removeChild(this.quoteButton)
  }

  removeAttachButton() {
    //this.buttonGroupFileTools.removeChild(this.attachButton)
    this.buttonGroupFileTools.remove()
  }

  insertNewHeadingButton() {
    this.buttonGroupBlockTools.insertAdjacentHTML("afterbegin", this.headingButtonTemplate)
  }

  //insertPreButton() {
  //  this.buttonGroupBlockTools.insertAdjacentHTML("afterbegin", this.preButtonTemplate)
  //}

  insertHeadingDialog() {
    this.dialogsElement.insertAdjacentHTML("beforeend", this.dialogHeadingTemplate)
  }

  insertDividerElements() {
    this.buttonGroupBlockTools.insertAdjacentHTML("beforeend", this.horizontalButtonTemplate)
  }

  insertColorElements() {
    this.insertHighlightButton()
    this.insertRedButton()
  }

  insertHighlightButton() {
    this.buttonGroupTextTools.insertAdjacentHTML("afterbegin", this.highlightButtonTemplate)
  }

  insertRedButton() {
    this.buttonGroupTextTools.insertAdjacentHTML("afterbegin", this.redButtonTemplate)
  }

  get buttonGroupBlockTools() {
    return this.toolbarElement.querySelector("[data-trix-button-group=block-tools]")
  }

  get buttonGroupTextTools() {
    return this.toolbarElement.querySelector("[data-trix-button-group=text-tools]")
  }

  get buttonGroupFileTools() {
    return this.toolbarElement.querySelector("[data-trix-button-group=file-tools]")
  }

  get dialogsElement() {
    return this.toolbarElement.querySelector("[data-trix-dialogs]")
  }

  get originalHeadingButton() {
    return this.toolbarElement.querySelector("[data-trix-attribute=heading1]")
  }

  get codeButton() {
    return this.toolbarElement.querySelector("[data-trix-attribute=code]")
  }

  get quoteButton() {
    return this.toolbarElement.querySelector("[data-trix-attribute=quote]")
  }

  get attachButton() {
    return this.toolbarElement.querySelector("[data-trix-action=attachFiles]")
  }

  get toolbarElement() {
    return this.element.toolbarElement
  }

  get horizontalButtonTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-horizontal-rule" data-trix-action="x-horizontal-rule" tabindex="-1" title="Divider">Divider</button>'
  }

  get headingButtonTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-action="x-heading" title="Heading" tabindex="-1">Heading</button>'
  }

  get highlightButtonTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-highlight" data-trix-attribute="bg-yellow" title="Highlight" tabindex="-1">Highlight</button>'
  }

  get redButtonTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-red" data-trix-attribute="fg-red" title="RedText" tabindex="-1">Red</button>'
  }

  //get preButtonTemplate() {
  //  return '<button type="button" class="trix-button trix-button--icon trix-button--icon-pre" data-trix-attribute="pre" title="Monospace" tabindex="-1">Pre</button>'
  //}

  get dialogHeadingTemplate() {
    return `
      <div class="trix-dialog trix-dialog--heading" data-trix-dialog="x-heading" data-trix-dialog-attribute="x-heading">
        <div class="trix-dialog__link-fields">
          <input type="text" name="x-heading" class="trix-dialog-hidden__input" data-trix-input>
          <div class="trix-button-group">
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading1">H1</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading2">H2</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading3">H3</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading4">H4</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading5">H5</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading6">H6</button>
          </div>
        </div>
      </div>
    `
  }

}

function addHeadingAttributes() {
  Array.from(["h1", "h2", "h3", "h4", "h5", "h6"]).forEach((tagName, i) => {
    Trix.config.blockAttributes[`heading${(i + 1)}`] = { tagName: tagName, terminal: true, breakOnReturn: true, group: false }
  })
}

//function addPreAttributes() {
//  Trix.config.blockAttributes["pre"] = { tagName: "pre", terminal: true, breakOnReturn: false, group: false }
//}

function addFgColorAttributes() {
  Array.from(["red"]).forEach((color, i) => {
    Trix.config.textAttributes[`fg-${color}`] = { style: { color: color }, inheritable: true, parser: e => e.style.color == color }
  })
}

function addBgColorAttributes() {
  Array.from(["yellow"]).forEach((color, i) => {
      Trix.config.textAttributes[`bg-${color}`] = { style: { backgroundColor: color }, inheritable: true, parser: e => e.style.backgroundColor == color }
  })
}

function putToolbarAfterEditor(target) {
  var trix_form = target.toolbarElement.parentElement;
  var trix_toolbar = target.toolbarElement;
  var trix_editor = target.editor.element;
  trix_form.insertBefore(trix_editor, trix_toolbar);
}

function browserIsMobile() {
  // https://peterscene.com/blog/detecting-touch-devices-2018-update/
  return window.matchMedia("only (screen and max-width: 760px), (hover: none)").matches;
}
