// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.
import 'weakmap-polyfill';     // Helpatschools added this to support old ios safari
import "@stimulus/polyfills"   // Helpatschools added this to support old ios safari
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const weakMap = new WeakMap(); // Helpatschools added this to support old ios safari
const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// These are the yarn modules I added to support old ios safari:
// yarn add @stimulus/polyfills
// yarn add core-js
// yarn add regenerator-runtime
// yarn add weakmap-polyfill
// Look for import statements in app/javascript/controllers/index.js
//                           and app/javascript/src/multiple_select.js

